<template>
  <v-container fluid>
    <v-row
      justify="space-between"
      align="center"
      class="mb-2"
    >
      <v-col cols="auto">
        <h4>{{$_strings.invoice.TITLE_PAGE}}</h4>
      </v-col>
      <v-col cols="12" sm="5" md="3">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          :placeholder="$_strings.invoice.SEARCHNO"
          ref="search"
          v-model="searchText"
          @keyup.enter="setFilter"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="setFilter">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-sheet>
      <v-row
        dense
        justify="start"
        class="pa-5"
      >
        <v-col
          cols="6"
          sm="auto"
        >
          <span>{{ $_strings.invoice.DATE }}</span>
          <v-menu
            ref="menuFromDate"
            v-model="menuFromDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                outlined
                class="caption"
                placeholder="From Date"
                :value="dayjs(startDate).format('DD-MM-YYYY')"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              no-title
              :max="endDate"
              @change="() => {
                menuFromDate = false;
                fetchInvoice();
              }"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="mt-8 d-none d-sm-block">-</v-col>
        <v-col
          cols="6"
          sm="auto"
        >
          <v-menu
            ref="menuToDate"
            v-model="menuToDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                class="caption mt-6"
                outlined
                :value="dayjs(endDate).format('DD-MM-YYYY')"
                placeholder="To Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="startDate"
              v-model="endDate"
              no-title
              @change="() => {
                menuToDate = false;
                fetchInvoice();
              }"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="ml-sm-4">
          <span>Filter</span>
          <v-menu
            bottom
            :close-on-content-click="closeOnContentClick"
            width="300"
            offset-y
            z-index="1"
            allow-overflow
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                height="10"
                class="caption"
                prepend-inner-icon="mdi-filter"
                :placeholder="$_strings.invoice.FILTER"
                outlined
                dense
                readonly
                v-bind="attrs"
                @click="closeOnContentClick = false"
                v-on="on"
              ></v-text-field>
            </template>
            <v-list
              width="300"
              min-height="150"
              max-height="400"
              class="overflow-auto"
            >
              <section class="ml-4">
                <span class="caption font-weight-bold">{{ $_strings.invoice.FILTER_STATUS }}</span>
                <v-select
                  hide-details
                  chips
                  flat
                  :items="filterStatusBy"
                  :placeholder="$_strings.invoice.CHOOSE_STATUS"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="caption mb-4 mr-4"
                  dense
                  v-model="chipStatus"
                  @change="filterStatus()"
                  multiple
                  clearable
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      small
                      class="mb-2 font-weight-bold"
                      @click="select"
                      @click:close="removechipStatus(item)"
                    >
                      {{ item.text }}<br>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <span class="caption">{{item.text}}</span>
                  </template>
                </v-select>
                <span class="caption font-weight-bold">{{ $_strings.invoice.FILTER_TYPE }}</span>
                <v-select
                  hide-details
                  small-chips
                  flat
                  :items="filterTypeBy"
                  :placeholder="$_strings.invoice.CHOOSE_TYPE"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="caption mb-4 mr-4"
                  dense
                  v-model="chipsType"
                  @change="filterType"
                  multiple
                  clearable
                >
                  <template v-slot:selection="{ attrs, item,select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      small
                      class="mb-2 font-weight-bold"
                      @click="select"
                      @click:close="removeChipsType(item)"
                    >
                      {{ item.text }}<br>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <span class="caption">{{item.text}}</span>
                  </template>
                </v-select>
                <span class="caption font-weight-bold">{{ $_strings.invoice.TRANSPORTER }}</span>
                <v-autocomplete
                  hide-details
                  class="caption mb-4 mr-4"
                  flat
                  dense
                  :items="get3plCompany"
                  :loading="isLoading3plCompanyList"
                  @change="onChangeCompany"
                  @input.native="search3plCompany"
                  item-value="id"
                  item-text="companyName"
                  :placeholder="$_strings.invoice.FILTER_3PL"
                  clearable
                > </v-autocomplete>
              </section>
              <section class="pl-2 pr-2">
                <v-btn
                  depressed
                  class="mt-2 white--text"
                  color="red"
                  block
                  @click="closeOnContentClick = true"
                >
                  {{$_strings.common.CLOSE}}
                </v-btn>
              </section>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        calculate-widths
        :items="itemsInvoice"
        item-key="invoiceNo"
        :server-items-length="invoiceListTotalEntry"
        :options.sync="pagination"
        :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      >
        <template v-slot:[`item.invoiceNo`]=items>
          <span>{{ items.item.invoiceNo }}</span>
          <br/>
          <span>{{ dateFormat(items.item.invoiceDate) }}</span>
        </template>
        <template v-slot:[`item.billedToCompanyName`]=items>
          <span>{{ items.item.billedToCompanyName }}</span>
        </template>
        <template v-slot:[`item.periodStart`]=items>
          <span>{{ countMonths(items.item.periodStart, items.item.periodEnd) }} Bulan</span>
          <br/>
          <span class="size-12">{{ dateFormat(items.item.periodStart) }} - {{ dateFormat(items.item.periodEnd) }}</span>
        </template>
        <template v-slot:[`item.lokasiAsal`]=items>
          <span>{{ items.item.lokasiAsal ? items.item.lokasiAsal : '-' }}</span>
        </template>
        <template v-slot:[`item.lokasiTujuan`]=items>
          <span>{{ items.item.lokasiTujuan ? items.item.lokasiTujuan : '-' }}</span>
        </template>
        <template v-slot:[`item.totalAmount`]=items>
          <span>Rp. {{ formatAmount(items.item.totalAmount) }}</span>
          <br/>
          <span class="size-12" v-if="items.item.invoiceType === 'Pengiriman'">{{ items.item.totalShipment }} Pesanan</span>
          <span class="size-12" v-else>{{ items.item.totalShipment }} Biaya</span>
        </template>
        <template v-slot:[`item.invoiceType`]=items>
          <span>{{ items.item.invoiceType }}</span>
        </template>
        <template v-slot:[`item.createdBy`]=items>
          <span>{{ items.item.createdBy }}</span>
          <br/>
          <span class="size-12">{{ dateFormat(items.item.createdAt) }}</span>
        </template>
        <template v-slot:[`item.status`]=items>
          <span>{{ items.item.status }}</span>
        </template>
        <template v-slot:[`item.actionColumn`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                x-small
                class="mr-2"
                color="primary"
                @click="exportPdf(item)"
                @click.stop.prevent>
                <v-icon>mdi-file-pdf-box</v-icon>
              </v-btn>
            </template>
            <span>File PDF</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.status === 'Proforma'"
                v-bind="attrs"
                v-on="on"
                x-small
                icon
                class="mr-2"
                color="primary"
                @click="exportExcel(item)"
                @click.stop.prevent>
                <v-icon> mdi-file-excel-box </v-icon>
              </v-btn>
            </template>
            <span>File XLS</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.status === 'Proforma'"
                v-bind="attrs"
                v-on="on"
                x-small
                icon
                class="mr-2"
                color="primary"
                @click="modalEmail(item)"
                @click.stop.prevent >
                <v-icon> mdi-email </v-icon>
              </v-btn>
            </template>
            <span>Email</span>
          </v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          <span>
            {{$_strings.invoice.INVOICE_LIST}}
            <span v-if="itemsInvoice.length">
              {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
            </span>
          </span>
        </template>
      </v-data-table>
    </v-sheet>
    <v-row>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="700"
      >
        <v-card>
          <v-card-title>
            <span class="headtitle-email">Masukan Email Tujuan</span>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-toolbar-items>
                  <v-btn
                    icon
                    color="black"
                    dark
                    @click="closeModal()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-col>
            </v-row>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col>
                  <v-subheader>Email</v-subheader>
                </v-col>
                <v-col
                  cols="10"
                  class="mr-4"
                >
                  <v-autocomplete
                    outlined
                    dense
                    :items="getEmail"
                    :search-input.sync="searchEmail"
                    item-value="email"
                    item-text="email"
                    v-model="form.email"
                    multiple
                    chips
                    persistent-hint
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeEmail(item)"
                      >
                        {{ item.email }}<br>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>{{item.email}}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  outlined
                  small
                  class="mr-2 ml-auto"
                  @click="closeModal()"
                >
                  {{$_strings.common.CANCEL}}
                </v-btn>
                <v-btn
                  depressed
                  small
                  color="primary"
                  class="white--text"
                  :loading="submitingEmail"
                  @click="submitEmail"
                >
                  {{$_strings.common.SAVE}}
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import {
  dateFormat,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  thousandSeparated,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';

export default {
  name: 'InvoiceList',
  created() {
    this.get3plCompanyList();
  },
  data() {
    return {
      isLoading: false,
      chipStatus: '',
      chipsType: '',
      filterStatusInv: '',
      filterTypeInv: '',
      items: [],
      dataInvoice: {},
      itemsInvoice: [],
      searchText: '',
      valid: false,
      invoiceListPage: 1,
      invoiceListSize: 10,
      invoiceListTotalEntry: 0,
      form: {
        email: [],
      },
      headers: [
        {
          text: this.$_strings.invoice.INVOICE_NO,
          value: 'invoiceNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.invoice.CUSTOMER_NAME,
          value: 'billedToCompanyName',
          class: 'white--text primary text-capitalize',
          width: '180px',

        },
        {
          text: this.$_strings.invoice.ORDER_PERIODE,
          value: 'periodStart',
          class: 'white--text primary text-capitalize',
          width: '200px',

        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'lokasiAsal',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'lokasiTujuan',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.invoice.INVOICE_TOTAL,
          value: 'totalAmount',
          class: 'white--text primary text-capitalize',
          width: '150px',

        },
        {
          text: this.$_strings.invoice.INVOICE_TYPE,
          value: 'invoiceType',
          class: 'white--text primary text-capitalize',
          width: '180px',

        },
        {
          text: this.$_strings.invoice.CREATED_BY,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          width: '180px',

        },
        {
          text: this.$_strings.invoice.STATUS,
          value: 'status',
          class: 'white--text primary text-capitalize',
          width: '150px',

        },
        {
          text: this.$_strings.invoice.ACTION,
          value: 'actionColumn',
          align: 'left',
          class: 'white--text primary text-capitalize',
          sortable: false,
          width: '10vw',
        },
      ],
      filterStatusBy: this.$_strings.invoice.FILTER_STATUS_LIST,
      filterTypeBy: this.$_strings.invoice.FILTER_TYPE_LIST,
      expanded: [],
      pagination: defaultPagination(),
      menuFromDate: false,
      menuToDate: false,
      startDate: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      totalItems: 0,
      subOrderLoading: false,
      dialog: false,
      getEmail: [],
      searchEmail: null,
      get3plCompany: [],
      company3pl: '',
      closeOnContentClick: false,
      invoiceId: '',
      companyId: '',
      submitingEmail: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchInvoice();
      },
      deep: true,
    },
    searchEmail(val) {
      if (!this.companyId) return;
      if (val === null) {
        this.$_services.invoice.emailList(this.companyId)
          .then((res) => {
            this.getEmail = res.data.contents;
          });
      } else {
        this.$_services.invoice.emailSearchList(val, this.companyId)
          .then((res) => {
            this.getEmail = res.data.contents;
          });
      }
    },
  },
  methods: {
    dayjs,
    dateFormat,
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
    countMonths(start, end) {
      const sDate = new Date(start).getMonth();
      const eDate = new Date(end).getMonth();
      const sYear = new Date(start).getFullYear() * 12;
      const eYear = new Date(end).getFullYear() * 12;
      const difference = (eYear + eDate) - (sYear + sDate);
      if (difference === 0) {
        return 1;
      }
      return difference;
    },
    setFilter() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchInvoice();
    },
    filterStatus() {
      this.filterStatusInv = this.chipStatus;
      this.setFilter();
    },
    filterType() {
      this.filterTypeInv = this.chipsType;
      this.setFilter();
    },
    search3plCompany(event) {
      this.isLoading3plCompanyList = true;
      this.$_services.invoice.companySearch(event.target.value)
        .then((res) => {
          this.get3plCompany = res.data.contents;
          this.isLoading3plCompanyList = false;
        }).finally(() => {
          this.isLoading3plCompanyList = false;
        });
    },
    removeChipsType(item) {
      const idChips = this.chipsType.findIndex((e) => e === item.value);
      this.chipsType.splice(idChips, 1);
      this.chipsType = [...this.chipsType];
      this.filterType();
    },
    removechipStatus(item) {
      const idChips = this.chipStatus.findIndex((e) => e === item.value);
      this.chipStatus.splice(idChips, 1);
      this.chipStatus = [...this.chipStatus];
      this.filterStatus();
    },
    removeEmail(item) {
      this.form.email.splice(this.form.email.indexOf(item), 1);
      this.form.email = [...this.form.email];
    },
    fetchInvoice() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        name: this.searchText,
        sort: handleSortBy({ sortBy, sortDesc }),
        dateFrom: this.startDate,
        dateTo: this.endDate,
        statusInvoice: this.filterStatusInv,
        typeInvoice: this.filterTypeInv,
        companyType: 'TRANSPORTER',
        billedToCompanyId: this.company3pl,
      };
      this.isLoading = true;
      this.$_services.invoice.fetchInvoice(filters)
        .then((result) => {
          this.itemsInvoice = result.data.contents;
          this.invoiceListTotalEntry = result.data.totalData;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getEmailList() {
      this.$_services.invoice.emailList(this.companyId)
        .then((res) => {
          this.getEmail = res.data.contents;
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
    get3plCompanyList() {
      this.isLoading3plCompanyList = true;
      this.$_services.invoice.companySearch()
        .then((res) => {
          this.get3plCompany = res.data.contents;
          this.isLoading3plCompanyList = false;
        }).finally(() => {
          this.isLoading3plCompanyList = false;
        });
    },
    onChangeCompany(val) {
      this.company3pl = val;
      this.fetchInvoice();
    },
    modalEmail(item) {
      this.dialog = true;
      this.invoiceId = item.id;
      this.companyId = item.billedToCompanyId;
      this.getEmailList();
    },
    closeModal() {
      this.dialog = false;
      this.invoiceId = '';
      this.companyId = '';
    },
    exportPdf(item) {
      if (item.invoiceNo) {
        this.$_services.invoice.exportPdfInvoice(item.id).then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          const { invoiceNo } = item;
          const invoiceLink = invoiceNo.replace(/\//g, '_');
          link.download = `invoice-${invoiceLink}.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
        return;
      }
      const parameter = {
        invoiceId: item.id,
        invoiceType: 'TRANSPORTER',
        exportFile: 'PDF',
      };
      this.$_services.invoice.exportPdfInvoiceProforma(parameter).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'invoice-proforma.pdf';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    exportExcel(item) {
      const parameter = {
        invoiceId: item.id,
        invoiceType: 'TRANSPORTER',
        exportFile: 'EXCEL',
      };
      this.$_services.invoice.exportPdfInvoiceProforma(parameter).then((response) => {
        const blob = new Blob([response.data], { type: 'application/xls' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'invoice-proforma.xls';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    submitEmail() {
      if (this.form.email.length < 1) {
        this.$dialog.notify.error('Mohon periksa kembali field yang kosong');
        return;
      }
      this.submitingEmail = true;
      this.$_services.invoice.sendEmail(this.form.email, this.invoiceId)
        .then(() => {
          this.$dialog.notify.success('Berhasil mengirimkan proforma ke email.');
        }).finally(() => {
          this.submitingEmail = false;
          this.dialog = false;
          this.$refs.form.reset();
          this.fetchInvoice();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-chip.v-size--default {
      font-size: 12px;
      margin-top: 3px;
  }
  .size-12 {
    font-size: 12px;
  }
  .size-10 {
    font-size: 10px;
  }
</style>
